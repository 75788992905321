



















































































































































































import ViewModel from '@/views/company/Index.ts';
export default ViewModel;
